/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

angular.module('zeppelinWebApp').controller('MainCtrl', MainCtrl);

function MainCtrl($scope, $rootScope, $window, arrayOrderingSrv, $http, baseUrlSrv,
                  $httpParamSerializer, $cookieStore, $location, $timeout, $cookies) {
  'ngInject';

  $scope.looknfeel = 'default';

  let init = function() {
    getConfig();
  };

  let getConfig = function() {
    $http({
      method: 'GET',
      url: baseUrlSrv.getRestApiBase() + '/zetaris/config',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then(function successCallback(response) {
      let mode = response.data.body.lightningMode;
      if (mode.toLowerCase() === 'prod') {
        $rootScope.lightningURL = response.data.body.lightningURL;
        $rootScope.redirectURL = response.data.body.redirectURL;

        let isLoggedOut = Boolean($cookies.get('isLightningLoggedOut'));
        let idToken = $cookies.get('idToken');

        if (isLoggedOut || !idToken) {
          alert('Lightning has been logged out. Please login again.');
          $window.location.href = $rootScope.redirectURL;
        } else {
          login();
        }
      } else {
        console.log('on dev mode');
      }
    }, function errorCallback(errorResponse) {
      console.error(errorResponse.errorText);
    });
  };

  let login = function() {
    $scope.SigningIn = true;
    $http({
      method: 'POST',
      url: baseUrlSrv.getRestApiBase() + '/login',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: $httpParamSerializer({
        'userName': 'user',
        'password': 'password',
      }),
    }).then(function successCallback(response) {
      let options = {domain: `.${$rootScope.lightningURL}`, path: '/'};
      $cookies.remove('isLightningLoggedOut', options);
      $rootScope.ticket = response.data.body;
      $rootScope.$broadcast('loginSuccess', true);
      $scope.SigningIn = false;

      putCredentials(response.data.body.principal, response.data.body.idToken, response.data.body.orgId);

      window.addEventListener('visibilitychange', function(event) {
        let isLoggedOut = Boolean($cookies.get('isLightningLoggedOut'));
        let isClicked = !document.hidden;

        if (isClicked && isLoggedOut) {
          alert('Lightning has been logged out. Please login again.');
          $window.location.href = $rootScope.redirectURL;
        } else {
          console.log('');
        }
      });

      // redirect to the page from where the user originally was
      if ($location.search() && $location.search()['ref']) {
        $timeout(function() {
          let redirectLocation = $location.search()['ref'];
          $location.$$search = {};
          $location.path(redirectLocation);
          $scope.$apply();
        }, 100);
      }
    }, function errorCallback(errorResponse) {
      console.error(errorResponse);
      $scope.loginParams.errorText = 'The username and password that you entered don\'t match.';
      $scope.SigningIn = false;
    });
    $scope.asIframe = (($window.location.href.indexOf('asIframe') > -1) ? true : false);
  };

  let putCredentials = function(username, password, orgId) {
    let newCredential = {
      'entity': 'spark',
      'username': username,
      'password': password,
      'orgId': orgId,
    };

    $http.put(baseUrlSrv.getRestApiBase() + '/credential', newCredential)
      .success(function(data, status, headers, config) {
        expiration();
        console.log('Success %o %o', status, data.message);
      })
      .error(function(data, status, headers, config) {
        console.log('Error %o %o', status, data.message);
      });
  };

  let expiration = function() {
    $http({
      method: 'GET',
      url: baseUrlSrv.getRestApiBase() + `/zetaris/expiration?token=${$cookies.get('idToken')}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then(function successCallback(response) {
      setTokenTimeout(response);
    }, function errorCallback(errorResponse) {
      console.error(errorResponse.errorText);
    });
  };

  let refreshToken = function() {
    $http({
      method: 'GET',
      url: baseUrlSrv.getRestApiBase() + `/zetaris/refreshToken?token=${$cookies.get('refreshToken')}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then(function successCallback(response) {
      let options = {domain: `.${$rootScope.lightningURL}`, path: '/'};
      $cookies.put('idToken', response.data.body.idToken, options);
      $cookies.put('refreshToken', response.data.body.refreshToken, options);

      setTokenTimeout(response);
    }, function errorCallback(errorResponse) {
      console.error(errorResponse.errorText);
    });
  };

  let setTokenTimeout = function(response) {
    let expirationInSecs = response.data.body.expiration;
    let expirationInMillis = expirationInSecs * 1000;
    let expireOn = expirationInMillis - Date.now();
    let fiveMinutes = 300000;
    let refreshNow = expireOn < fiveMinutes;

    if (refreshNow) {
      refreshToken();
    } else {
      setTimeout(() => {
        refreshToken();
      }, expireOn - fiveMinutes);
    }
  };

  init();

  $rootScope.$on('setIframe', function(event, data) {
    if (!event.defaultPrevented) {
      $scope.asIframe = data;
      event.preventDefault();
    }
  });

  $rootScope.$on('setLookAndFeel', function(event, data) {
    if (!event.defaultPrevented && data && data !== '' && data !== $scope.looknfeel) {
      $scope.looknfeel = data;
      event.preventDefault();
    }
  });

  // Set The lookAndFeel to default on every page
  $rootScope.$on('$routeChangeStart', function(event, next, current) {
    $rootScope.$broadcast('setLookAndFeel', 'default');
  });

  $rootScope.noteName = function(note) {
    if (!_.isEmpty(note)) {
      return arrayOrderingSrv.getNoteName(note);
    }
  };

  $rootScope.notePath = function(note) {
    if (!_.isEmpty(note)) {
      return arrayOrderingSrv.getNotePath(note);
    }
  };

  BootstrapDialog.defaultOptions.onshown = function() {
    angular.element('#' + this.id).find('.btn:last').focus();
  };

  // Remove BootstrapDialog animation
  BootstrapDialog.configDefaultOptions({animate: false});
}
